$primary-color: #e0e1e0;
$secondary-color: #d1cbc7;
$tertiary-color: #c6538c;
$border-color: #cccaca;

.nav {
  .mobileNavContainer {
    display: none;
  }

  .navContainer {
    // border: 2px solid grey;
    display: flex;
    justify-content: space-between;
    background-color: white;
    box-shadow: 0 1px 1px 0.1px rgba(0, 0, 0, 0.2);
    //sticky nav
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;

    .logoDiv {
      // border: 2px solid purple;
      margin-left: 1vw;

      .logoImage {
        // border: 2px solid green;
        max-width: 60%;
        height: auto;
      }
    }

    .linksDiv {
      // border: 1px solid black;
      width: 40%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      font-family: "Roboto";
      text-transform: uppercase;
      text-decoration: inherit;

      .navStyles {
        color: black;
        text-decoration: none;
        position: relative;
      }

      .navStyles:hover {
        color: grey;
      }

      .navStyles:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 0;
        left: 0;
        background-color: grey;
        visibility: hidden;
        transform: scaleX(0);
        transition: all 0.3s ease-in-out;
      }

      .navStyles:hover:before {
        visibility: visible;
        transform: scaleX(1);
      }
    }

    .socialMediasDiv {
      // border: 1px solid blue;
      width: 10%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-right: 5%;
    }
  }
}

//Mobile Nav Styles
@media screen and (max-width: 600px) {
  .nav {
    //border: 2px solid red;
    width: 100%;
    border-bottom: 1px solid $border-color;
    margin-bottom: 2vh;
    box-shadow: 0 1px 3px $border-color, 0 1px 2px $border-color;

    .navContainer {
      display: none;
    }
    .mobileNavContainer {
      // border: 2px solid blue;
      display: initial;
      width: 100%;
      display: flex;
      flex-direction: column;

      .staticMenu {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid $border-color;

        .logoDiv {
          // border: 2px solid green;
          padding-left: 12px;

          img {
            max-width: 50%;
            height: auto;
          }
        }

        .hamMenu {
          // border: 1px solid steelblue;
          display: flex;
          align-items: center;
          text-transform: uppercase;
          margin-right: 22px;
          font-size: 1rem;
          font-family: "Open Sans Condensed", sans-serif;
          font-weight: 600;
        }
      }

      .socialMediasDiv {
        //background-color: red;
        width: 22%;
        display: flex;
        justify-content: space-evenly;
      }

      .columnOfLinks {
        // border: 2px solid purple;
        min-height: 33vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        font-family: "Open Sans Condensed", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        text-decoration: inherit;

        .navStyles {
          color: black;
          text-decoration: none;
          position: relative;
        }

        .navStyles:hover {
          color: grey;
        }

        .navStyles:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: grey;
          visibility: hidden;
          transform: scaleX(0);
          transition: all 0.3s ease-in-out;
        }

        .navStyles:hover:before {
          visibility: visible;
          transform: scaleX(1);
        }
      }
    }
  }
}
