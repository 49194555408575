.footerContainer {
  //border: 2px solid red;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;
  border-top: 1px solid #cccaca;
  margin-top: 5vh;

  img {
    max-width: 35%;
    height: auto;
  }

  p {
    font-family: "Roboto";
    font-size: 0.9rem;
  }

  .footerText {
    display: flex;
    align-items: center;

    span {
      margin-left: 5px;
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 600px) {
  .footerContainer {
    width: 100%;

    .footerText {
      flex-direction: column;

      span {
        display: none;
      }
    }
  }
}
