.callToActionContainer {
  //border: 1px solid red;
  display: flex;
  padding:5px;
  flex-direction: column;
  align-content: center;
  margin-top: 2vh;
  

  .callToActionLinks {
    color: black;
    font-weight: 600;
    text-decoration: none;
    position: relative;
    padding: 10px 0;
  }

  .callToActionLinks:hover {
    color: grey;
  }

  .callToActionLinks:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: grey;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }

  .callToActionLinks:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }
}
